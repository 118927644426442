/*******************************/
/*   Variables                 */
/*******************************/

:root {
    /* fonts */
    --bebas-neue: BebasNeue;
    --arial-nova: ArialNova;
    --aparajita: Aparajita;
    --helvetica-neue-medium: HelveticaNeueMedium;
    --helvetica-regular: Helvetica;
    
    --primary-colour : ;
    --secondary-colour: #ffffff;
    --sub-colour: ;

    --black : #000000;
    --black-003 : #333333;
    --error : #ff0000;
    --gold : #bf9000;
    --gold-001: #F6F5F0;
    --gold-002: #f3b600;
    --grey-dark : #808080;
    --grey-light : #bfbfbf;
    --grey-002 : #c8c8c8;
    --grey-003 : #cecece;
    --grey-004 : #404040;
    --grey-005 : #505050;
    --grey-007 : #7e7e7e;
    --grey-009 : #9a9a9a;
    --grey-010 : #d0cece;
    --grey-011: #2C303C;
    --grey-012: #434961;
    --pink : #fb6166;
    --purple : #722ee3;
    --success : #228B22;
}

/* media queries */
@custom-media --x-large-viewport (min-width: 1400px);
@custom-media --large-viewport (max-width: 1200px);
@custom-media --medium-viewport (max-width: 1024px);
@custom-media --small-viewport (max-width: 768px);
@custom-media --x-small-viewport (max-width: 480px);