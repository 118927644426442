/*******************************/
/*      Sign up Screen         */
/*******************************/

.signup {
    position: fixed;
    z-index: 10;
}

.signup__image-container {
    position: relative;
    width: 100vw;
    height: 100vh;
}

.signup__background-image {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.signup__signup-credits {
    position: absolute;
    padding: 10px 15px;
    background:var(--secondary-colour);
    right: calc(50px);
    bottom: 50px;
    text-align: center;
}

.signup-container {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.signup-page-form-wrapper {
    margin: auto;
    background: var(--secondary-colour);
    width: 500px;
}

.signup-form__header {
    background-color: var(--gold-001);
    padding: 14px;
    text-align: center;
}

.signup__header-text {
    font-size: 18px;
}

.signup__form-container {
    padding: 20px 100px;
}

.eb-forms__form-group--login,
.eb-forms__form-group--signup {
    flex-direction: column;
    align-items: flex-start;
}

.eb-forms__form-group.eb-forms__form-group--signup {
    margin-bottom: 5px;
}

.signup__button {
    width: 100%;
}

.signup__password-hint {
    font-size: 12px;
    line-height: 12px;
    padding: 10px 0;
}

.signup-form__footer {
    text-align: center;
    padding: 20px 0;
    margin: 0 20px;
}

.passwordMask { 
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end; 
}

.passwordMask a {
    top: 0 !important;
    right: -1px !important;
    margin-top: -20px !important;
    padding: 8px 9px !important;
    font-size: 12px !important;
    letter-spacing: 1px !important;
    background: var(--gold) !important;
    color: #fff !important;
    border-radius: 3px;
    height: 35px;
}

@media screen and (max-width: 768px) {
    .auth-form-container--signup-screen {
        padding: 0 30px;
    }
}