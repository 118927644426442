.accordion {
    width: 30%;
    min-width: 470px;
  }
  .panel {
    /* background-color: #f0ebe1; */
  }
  .panel__label {
    position: relative;
    display: block;
    width: 100%;
    background: none;
    border: none;
    text-align: left;
    padding: 10px 40px 0 0;
    font-family: inherit;
    transition: color 0.2s linear;
    cursor: pointer;
    color: var(--grey-004);
    font-size: 1.3rem;
    line-height: 1.5;
    font-weight: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-bottom: 1px solid var(--grey-003);
    padding-bottom: 2px;
  }
  .panel__label:focus {
    outline: none;
  }
  .panel__label:after,
  .panel__label:before {
    content: '';
    position: absolute;
    right: 25px;
    top: 50%;
    width: 10px;
    height: 2px;
    margin-top: -2px;
    background-color: #372717;
  }
  .panel__label:before {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    transition: -webkit-transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
    transition: transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
    transition: transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1), -webkit-transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
  }
  .panel[aria-expanded='true'] .panel__content {
    opacity: 1;
  }
  .panel[aria-expanded='true'] .panel__label {
    color: #957029;
  }
  .panel[aria-expanded='true'] .panel__label:before {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  .panel__inner {
    overflow: hidden;
    will-change: height;
    transition: height 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
  }
  .panel__content {
    font-size: 14px;
    color: #756658;
    opacity: 0;
    transition: opacity 0.3s linear 0.18s;
    padding: 5px 0;
    line-height: 26px;
  }
  .panel:not(:last-child) {
    margin-bottom: 3px;
  }
  