/*******************************/
/*      Rich Text              */
/*******************************/

.rich-text,
.rich-text p {
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    color: var(--grey-005);
}

.rich-text strong {
    color: var(--black);
    font-weight: bolder;
    font-size: 15px;
}

@media screen and (max-width: 1200px) { 
  
}

@media screen and (max-width: 768px) {

}