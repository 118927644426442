/*******************************/
/*      Gallery                */
/*******************************/
@import "../../../../../node_modules/react-image-gallery/styles/css/image-gallery.css";

.rodal--gallery-modal .rodal-dialog {
    display: flex;
    flex-direction: row;
    /* width: 100% !important; */
    width: auto !important;
    border-radius: 5px;
    min-height: 600px;
    max-width: 1600px;
}

.rodal--gallery-modal .rodal-close {
    right: -30px;
    top: 0;
    width: 18px;
    height: 18px;
    z-index: 2;
}

.rodal--gallery-modal .rodal-close:before, 
.rodal--gallery-modal .rodal-close:after {
    background: var(--secondary-colour);
    height: 5px;
    border-radius: 0;
}

.gallery-modal__column {
    height: 100%;
}

.gallery-modal__column--media {
    flex: 1;
    display: flex;
    justify-content: center;
    background: rgba(0,0,0,0.9);
    width: 70%;
}

.image-gallery-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.gallery-modal__column--info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 35%;
    max-width: 335px;
}

.gallery-model__header {
    border-bottom: 1px solid var(--grey-light);
    padding: 10px;
}

.gallery-model__info-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--black);
    text-transform: uppercase;
}

.gallery-model__info-link:hover, 
.gallery-model__info-link:focus {
    color: var(--gold);
}

.gallery-modal__body {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
}

.gallery-modal__body-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.gallery-modal__info,
.gallery-model__comment {
    margin: 0 0 10px;
}

.gallery-model__comment blockquote {
    font-size: 1.2rem;
    position: relative;
    padding: 10px 0 0 25px;
    margin: 0;
}

.gallery-model__comment blockquote::before {
    content: '\f10d';
    font: normal normal normal 14px/1 FontAwesome;
    position: absolute;
    top: 5px;
    left: 5px;
    color: var(--grey-002);
  }

.gallery-model__comments-list {
    padding: 10px 0;
    border-top: 1px solid var(--grey-light);
}

.gallery-model__footer {
    padding: 10px;
    display: flex;
    align-items: center;
    border-top: 1px solid var(--grey-light);
}

.eb-forms input[type="text"].gallery-model__input {
    border: 0; 
    margin: 0 5px;
}

.btn-white.gallery-modal__post-btn {
    height: 35px;
    width: auto;
    margin: 0;
    padding: 1px 7px 2px;
}

.rodal-close:before, .rodal-close:after {
    background: #000 !important;
}

.rodal--gallery-modal .rodal-close {
    right: 5px;
    top: 5px;
}

.rodal--gallery-modal .rodal-close:focus {
    outline: none;
}

.rodal--gallery-modal .rodal-dialog {
    min-height: 680px;
}

.gallery-model__info-link {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.logoEqualFontHeader {
    font-size: 25px;
    color: var(--gold);
    line-height: 30px;
    text-transform: capitalize;
}

.rodal--gallery-modal .rodal-close:before, .rodal--gallery-modal .rodal-close:after {
    height: 3px;
}

.logoEqualFontHeader:hover {
    color: var(--black);
}

.gallery-modal__body-header span {
    font-size: 14px;
}

.gallery-modal__body h1, 
.gallery-modal__body h2, 
.gallery-modal__body h3, 
.gallery-modal__body h4, 
.gallery-modal__body h5, 
.gallery-modal__body h6 {
    color: var(--gold);
    text-transform: capitalize;
}

.event__keywords {
    display: inline-block;
    padding: 5px 15px;
    border-radius: 24px;
    margin: 0 5px 5px;
    font-size: 13px;
    background-color: var(--gold);
    color: #fff;
    text-transform: capitalize;
}

.gallery-model__comment blockquote {
    font-size: 14px;
    color: var(--black);
    padding: 5px 0 0 25px;
}

.btn-white.gallery-modal__post-btn {
    height: 35px;
    width: auto;
    margin: 0;
    padding: 1px 7px 2px;
    background-color: var(--gold);
    color: var(--gold-001);
    min-width: 75px;
    text-align: center;
}

/* ==================================== */

.image-gallery {
    width: 100%;
}

.image-gallery-thumbnail {
    position: relative;
}

.image-gallery-thumbnail .image-gallery-thumbnail-inner {
    position: unset;
}

.image-gallery-slide:after,
.image-gallery-thumbnail:after {
    content: '';
    display: block;
    padding-bottom: 50%;
}

.image-gallery-slide .image-gallery-image,
.image-gallery-thumbnail .image-gallery-thumbnail-image {
    position: absolute;
    height: 100%;
    object-fit: cover;
    object-position: center;
    left: 0;
    top: 0;
}

.image-gallery-slide-wrapper {
    position: relative;
    height: calc(100% - 64px);
}

.image-gallery-swipe,
.image-gallery-slides,
.image-gallery-slide {
    height: 100%;
}

@media screen and (max-width: 1400px) {
    .rodal--gallery-modal .rodal-dialog {
        max-width: 935px;
        min-height: 480px;
    }
}

@media screen and (max-width: 1000px) {
    .rodal--gallery-modal .rodal-dialog {
        width: 700px !important;
        min-height: 330px;
    }
}

@media screen and (max-width: 767px) {
    .rodal--gallery-modal .rodal-dialog {
        width: 100% !important;
        flex-direction: column;
        min-height: 70vh;
        overflow: hidden;
    }

    .gallery-modal__column--media,
    .gallery-modal__column--info {
        width: 100%;
    }

    .gallery-modal__column--info {
        overflow-y: auto;
    }

    .image-gallery-slide-wrapper {
        height: 100%;
    }
}