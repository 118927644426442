/*******************************/
/*      Gallery                */
/*******************************/

.gallery {
    display: flex;
    flex-wrap: wrap;
    padding: 30px 30px 10px;
}

.infinite-scroll-component.gallery__inifinite-scroll {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.gallery__image {
    position: relative;
    width: calc(var(--unit-four) - 14px);
    height: 100%;
    margin: 0 0 20px 21px;
    cursor: pointer;
    overflow: hidden;
}

.gallery__image::after {
    content: "";
    display: block;
    padding-bottom: 100%;
}

.gallery__image:first-child,
.gallery__image:nth-child(3n+1) {
    margin-left: 0;
}

.gallery__image-item {
    position: absolute;
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
    transition: 1.2s opacity ease-in-out; 
    opacity: 1;
}

.gallery__image--multiple {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
}

.gallery__image-hover-overlay {
    position: absolute;
    display: none;
    background: rgba(0, 0, 0, .5);
    width: 100%;
    height: 100%;
    transition: all ease-in-out .5s;
    justify-content: center;
    align-items: center;
}

.gallery__image:hover .gallery__image-hover-overlay,
.gallery__image:focus .gallery__image-hover-overlay {
    display: flex;
    transition: all ease-in-out .5s;
}

.gallery__overlay-icons {
    font-size: 16px;
    color: var(--secondary-colour);
    margin:  0 30px 0 0;
}

.gallery__overlay-icons .fa {
    margin:  0 5px 0 0;
}

.fa-heart {
    color: var(--gold);    
    cursor: pointer;
}

.fa-heart:hover {
    color: #f00 !important;    
}

.likedHeart {
    color: #f00 !important;
    pointer-events: none;
}

@media screen and (max-width: 1400px) {
    .gallery__image--multiple {
        width: 20px;
    }
}

@media screen and (max-width: 768px) {
    .gallery__overlay-icons {
        margin-right: 10px;
    }
}

@media screen and (max-width: 767px) {
    .gallery {
        padding: 10px 10px 6px;
    }

    .gallery__image {
        width: calc(var(--unit-four) - 3px);
        margin: 0 0 4px 4px;
    }

    .rodal--gallery-modal .rodal-close {
        padding: 10px;
        background: var(--secondary-colour);
    }

    .gallery__image--multiple {
        width: 15px;
        top: 0;
        right: 0;
    }
}