/*******************************/
/*   Supplier Profile Screen    */
/*******************************/

.filter--supplier-profile {
    font-size: 14px;
    color: var(--grey-dark);
    padding: 10px 20px 10px 140px;
}

.supplier-profile__list {
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin: 0 15px;
    text-transform: capitalize;
}

.supplier-profile__list:first-of-type {
    margin-left: 0;
}

.supplier-profile__list--active,
.supplier-profile__list:hover,
.supplier-profile__list:focus {
    color: var(--gold);
}

.supplier-profile__list--active:after {
    content: '';
    border-bottom: 5px solid var(--gold);
    position: absolute;
    width: 100%;
    bottom: -12px;
    left: 0;
}

.supplier-profile__number{
    margin: 0 15px;
}

.supplier-profile__contact-details {
    order: 2;
    display: flex;
    align-items: center;
}

.supplier-profile__contact-details img {
    width: 20px;
    margin: 0 5px;
}

.supplier-profile__overview {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 30px;
}

.supplier-profile__description {
    flex: 1 80%;
    padding-right: 30px;
}

.supplier-profile__sidebar {
    flex: 1 20%;
}

.supplier-profile__sidebar p,
.supplier-profile__address {
    margin-top: 0;
    font-size: 14px;
    line-height: 20px;
    color: var(--grey-005);
    font-style: normal;
}

.supplier-profile__address {
    margin-bottom: 16px;
}

.supplier-profile__save,
.supplier-profile__write-review {
    background: var(--gold);
    color: var(--secondary-colour);
    border: none;
    padding: 0 15px;
    font-size: 1.4rem;
    min-width: 100px;
    margin-bottom: 15px;
}

.supplier-profile__social-media {
    margin: 10px 0;
}

.supplier-profile__social-media-list {
    display: inline-block;
    margin: 0 5px 5px 0;
}

.supplier-profile__social-media-link {
    display: block;
    width: 30px;
}

.supplier-profile__reviews-container {
    padding: 30px;
    /* margin: 0 auto; */
}

.supplier-profile__reviews-header {
    display: flex;
    justify-content: space-between;
}

.supplier-profile__reviews-heading {
    color: var(--grey-dark);
}

.supplier-profil__review {
    border-bottom: 1px solid var(--grey-light);
    padding: 20px 0;
}

.supplier-profil__review:last-of-type {
    border-bottom: none;
}

.supplier-profile__review-company-logo {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    overflow: hidden;
    position: relative;
}

.supplier-profile__review-company-logo-image {
    object-fit: cover;
    object-position: center;
    position: absolute;
    height: 100%;
    width: 100%;
}

.supplier-profile__review-header {
    display: flex;
    padding: 0 0 15px;
}

.supplier-profile__company-details {
    padding-left: 15px;
}

.supplier-profile__review-company-name {
    margin-bottom: 5px;
}

.supplier-profile__review-comment {
    padding: 15px 0 0;
}

.profile__basic-infomation-labeles {
    color: var(--gold);
    font-weight: bold;
    font-size: 16px;
    min-width: 200px;
    width: 200px;
    font-style: normal;
}

.supplier-profile__reviews-container .rodal__main-container {
    display: flex;
    flex-wrap: wrap;
    padding: 15px;
    overflow-y: auto;
    height: 540px;
}

.overrided__tips-section {
    text-align: right;
    width: 100%;
}

td {
    font-size: 14px;
}

.each__review-header {

}

.supplier__telephone-container .react-tel-input .form-control {
    width: auto;
    cursor: default;
    border: none;
    background: none;
    height: auto;
    padding-left: 30px;
    color: var(--gold);
    pointer-events: none;
}

.supplier__telephone-container .react-tel-input .flag-dropdown {
    border: none;
    background: transparent;
}

.supplier__telephone-container .react-tel-input .selected-flag .arrow {
    display: none;
}

@media screen and (max-width: 1200px) {
}

@media screen and (max-width: 1000px) {
    .supplier-profile__reviews-container {
        padding: 30px 20px;
        width: var(--unit-twelve);
    }
}

@media screen and (max-width: 768px) {
    .filter--supplier-profile {
        padding: 10px 20px;
    }

    .supplier-profile__tabs {
        display: flex;
        justify-content: space-between;
    }

    .supplier-profile__number,
    .supplier-profile__website {
        margin-left: 0;
    }

    .supplier-profile__contact-details {
        display: none;
    }

    .supplier-profile__overview {
        display: block;
        padding: 20px;
    }

    .supplier-profile__description {
        padding: 0 0 30px;
    }

    .supplier-profile__save {
        padding: 10px 25px;
    }

    .supplier-profile__reviews-header {
        display: block;
    }

    .supplier-profile__write-review {
        padding: 10px 25px;
    }
}