/*******************************/
/*      Coming Soon Header     */
/*******************************/

.header-coming-soon {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    margin: 0 30px;
}

.header-coming-soon__header {
    display: flex;
    flex-direction: column;
    padding: 15px 0 0 15px;
}

.header-coming-soon__coming-soon-text {
    color: #FFF;
    font-weight: normal;
    margin-right: 30px;
    margin-top: -25px;
}

.header-coming-soon__logo {
    max-height: 100px;
    margin: 0 auto;
}

/* Responsive Styles */
@media screen and (max-width: 1200px) {
    .header-coming-soon {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }

}

@media screen and (max-width: 768px) {
    .header-coming-soon {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .header-coming-soon__logo {
        max-height: 80px;
    }
}