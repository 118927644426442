/*******************************/
/*         Activation          */
/*******************************/

.activation {
    position: fixed;
    z-index: 20;  
    top: 0;
}

.activation::after {
    /* content: '';
    position: fixed;
    min-height: 110vh;
    background-color: transparent;
    width: 100%;
    bottom: -10px;
    background-color: var(--black);  
    transition: all linear 0.2s; */
}

.activation.close::after {
    height: 0vh;
    bottom: 100%;
}

.activation__image-container {
    position: relative;
    width: 100vw;
    height: 100vh;
}

.activation__background-image {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.activation__signup-credits {
    position: absolute;
    padding: 10px 15px;
    background:var(--secondary-colour);
    right: calc(50px);
    bottom: 50px;
    text-align: center;
}

.activation-container {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.activation__form-wrapper {
    margin: auto;
    background: var(--secondary-colour);
    width: 500px;
    padding: 15px 20px;
}

.activation__btn-container {
    min-height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
}

.activation__heading,
.activation__intro {
    margin: 0 0 10px;
}

.activation-form__footer {
    text-align: center;
}

@media screen and (max-width: 1200px) {

}

@media screen and (max-width: 768px) {
    
}