/*******************************/
/*      Global Styles          */
/*******************************/

@font-face{
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/helvetica/Helvetica.ttf');
}

html {
  font-size: 10px;
  overflow-y: inherit;
}

html, body {
  margin: 0;
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

#main {
  flex-grow: 1;
}

body {
  font-family: var(--helvetica-regular);
  background-color: var(--secondary-colour);
  padding: 0;
  margin: 0;
  font-size: 10px;
  font-size: 1rem;
  line-height: 22px;
  overflow-x: hidden;
}

body.modal-open {
  overflow-y: hidden;
}

button:disabled {
  background: transparent;
  color: var(--gold);
  border: 2px solid;
  cursor: not-allowed;
}
input[type="button"]:disabled {
  background-color: #CFB53B;
  cursor: not-allowed; 
}

a { cursor: pointer }

.rodal-dialog h3 {
  color: #bf9000;
}

.text-center {
  text-align: center;
}

*, ::after, ::before {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

h1 { font-size: 4.8rem; line-height: 5rem; }
h2 { font-size: 3.6rem; line-height: 4.5rem; }
h3 { font-size: 3rem; line-height: 4rem; }
h4 { font-size: 2.4rem; line-height: 3.2rem; }
h5 { font-size: 2rem; line-height: 3rem; }
h6 { font-size: 1.5rem; line-height: 2.4rem; }

h1,
h2,
h3,
h4,
h5,
h6 { font-family: var(--helvetica-regular); margin: 0; }

p {
  margin: 0;
  font-size: 1.4rem;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

fieldset {
  position: relative;
}

.fieldset--inverse {
  padding: 15px 12px 25px;
  margin: 0;
}

legend {
  text-transform: uppercase;
  font-size: 12px;
  padding: 0 5px;
}

a {
  text-decoration: none;
  color: var(--gold);
}

a:hover,
a:focus {
  color: var(--gold);
  text-decoration: none;
}


#header {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--secondary-colour);
  z-index: 50;
  top: 0;
  transition: all ease-in-out 1s;
  border-bottom: 4px solid var(--black);
}

#header.scrolled {
  margin-bottom: 50px;
  transition: all ease-in-out 1s;
}

#footer {
  background: var(--black-003);
  padding: 10px 0;
  position: relative;
}

.form-wrapper {
  background: var(--secondary-colour);
  padding: 15px;
  width: var(--unit-three-half);
}

.form-heading {
  color: var(--grey-004);
  font-weight: normal;
  opacity: 0.9;
  margin: 0 0 15px;
}

/*** buttons ***/

.btn {
  cursor: pointer;
  height: 45px;
  font-size: 14px;
  cursor: pointer;
}

.btn:focus {
  outline: none;
}

.btn-gold {
  background: var(--gold);
  color: var(--secondary-colour);
  border: none;
  padding: 0 10px;
}

.btn-gold--inverse {
  position: relative;
  background: transparent;
  color: var(--gold);
  border: 2px solid var(--gold);
  padding: 10px 50px 10px 10px;
  border-radius: 24px;
}

.btn-gold--inverse:before {
  content: '\f00d';
  font: normal normal normal 14px/1 FontAwesome;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.btn-gold--inverse:after {
  content: '';
  background: var(--gold);
  transition: background 0.3s ease-in-out;
}

.btn-white {
  border: 2px solid var(--grey-003);
  background: var(--secondary-colour);
  padding: 10px 15px;
  margin-bottom: 10px;
  width: 100%;
  color: var(--grey-009);
  text-align: left;
}

.btn-pink {
  border: 2px solid var(--grey-003);
  background: var(--pink);
  padding: 10px 15px;
  margin-bottom: 10px;
  width: 100%;
  color: var(--secondary-colour);
  text-align: left;
}

.btn-purple {
  border: 2px solid var(--grey-003);
  background: var(--purple);
  padding: 10px 15px;
  margin-bottom: 10px;
  width: 100%;
  color: var(--secondary-colour);
  text-align: left;
}

.btn-transparent {
  position: relative;
  min-width: 120px;
  font-size: 12px;
  text-align: left;
  text-transform: uppercase;
  background: transparent;
  border: 2px solid transparent;
  color: var(--black);
  padding: 0;
  overflow: hidden;
  transition: all ease-in 0.2s;
}

.btn-transparent::before,
.btn-transparent::after {
  content: '';
  position: absolute;
  height: 45px;
  width: 45px;
  top: -2px;
}

.btn-transparent::before {
  content: '\f178';
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--black);
  right: -2px;
  z-index: 1;
}

.btn-transparent::after {
  background: var(--gold);
  opacity: 0;
  right: -45px;
  transition: all ease-in 0.2s;
}

.btn-transparent:hover {
  border: 2px solid var(--gold);
  padding: 0 10px;
}

.btn-transparent:hover::after {
  color: var(--secondary-colour);
  right: -2px;
  opacity: 1;
  transition: all ease-in 0.2s;
}

.btn-transparent:hover::before {
  color: var(--secondary-colour);
}

.popover {
  position: absolute;
  background: var(--secondary-colour);
  border: 1px solid rgba(0,0,0,.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
  border-radius: 4px;
  max-width: 276px;
  z-index: 2;
  right: 0;
}

.popover__content {
  padding: 15px;
}

.popover__arrow {
  position: absolute;
  top: -11px;
  right: 20%;
}

.popover__arrow:after {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 10px;
  top: 1px;
  margin-left: -10px;
  border-top-width: 0;
  border-bottom-color: #fff;
}

.popover .eb-forms select {
  margin: 0;
}

.loader,
.loader:before,
.loader:after {
  background: var(--gold);
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
  opacity: 1;
}

.loader.hide,
.loader.hide:before,
.loader.hide:after {
  opacity: 0;
  display: none;
}

.loader {
  color: var(--gold);
  /* text-indent: -9999em; */
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}

.loader span {
  opacity: 0;
  visibility: hidden;
}

.overlay {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5); 
  z-index: 20000;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  opacity: 1;
  visibility: visible;
  transition: all ease-in 0.2s;
}

.overlay--full {
  background: rgba(246, 245, 240, 1); 
}

.overlay.overlay--inner {
  position: absolute;
  height: 100%;
  width: 100%;
  background: var(--secondary-colour);
  z-index: 1;
}

.overlay.hide {
  opacity: 0;
  visibility: hidden;
  transition: all ease-in 0.2s;
}

.error { 
  font-size: 11px;
  color: red;
  display: block;
}

.dropzone {
  justify-content: center;
  align-items: center;
  border: 1px dashed var(--grey-010);
  color: var(--grey-007);
  padding: 15px;
  max-height: unset;
  overflow-y: auto;
  min-height: unset;
}

.dropzone .dz-message {
  margin: auto;
}

.dropzone .dz-preview .dz-image img {
  object-fit: cover;
  width: 100%;
  object-position: center;
}

.react-fancybox .box {
  z-index: 10000 !important;
}

.react-fancybox .close-btn {
  cursor: pointer !important;
  z-index: 1;
}

.react-fancybox .thumbnail img {
  cursor: pointer !important;
}

.rodal-mask {
  background: rgba(0, 0, 0, .5);
}

.modal-open .rodal,
.modal-open .rodal-mask {
  z-index: 5000;
}

.rodal--gallery-modal .rodal-dialog {
  z-index: 10000;
}

.fa {
  font-size: 16px !important;
}

.gold { color: var(--gold); }

.lazyload {
  width: 100%;
  height: 100%;
  position: absolute;
  border: 1px solid var(--grey-003);
  opacity: 0; 
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  transition: 1.2s opacity ease-in-out; 
  opacity: 1;
  cursor: pointer;
  z-index: 2;
  width: 50px;
  height: 50px;
  padding: 15px;
  background: var(--secondary-colour);
  border: 1px solid var(--gold);
  border-radius: 50%;
  box-shadow: 0 0 8px 0 rgba(0,0,0,0.15);
  outline: none;
}

.back-to-top__icon {
  transform: rotate(270deg) scale(1);
  transition: all ease-in-out 0.2s; 
}

.back-to-top:hover .back-to-top__icon {
  transform: rotate(270deg) scale(1.2);
}

.hide {
  opacity: 0;
}

.load-more-images {
  display: flex;
  width: 100%;
  justify-content: center;
  height: 50px;
}

.load-more-icon {
  height: 100%;
  width: 50px;
}

@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

@media screen and (max-width: 1200px) {

}

@media screen and (max-width: 768px) {

  h1 { font-size: 2em; line-height: 1.8em; }
  h2 { font-size: 1.625em; line-height: 1.7em; }
  h3 { font-size: 1.375em; line-height: 1.6em; }
  h4 { font-size: 1.125rem; line-height: 1.5em; }
  h5 { font-size: 1.1rem; line-height: 1.4em; }
  h6 { font-size: 1em; line-height: 1.3em; }

  .form-heading {
    text-align: center;
  }

  .btn {
    width: var(--unit-twelve);
    display: block;
  }

  #header,
  #header.scrolled {
    position: sticky;
    top: 0;
  }

  .btn-gold {
    min-width: auto;
  }

}

.eb-forms input[type="text"], .eb-forms input[type="password"], .eb-forms textarea {
  border-radius: 5px;
  font-size: 12px;
}