/*******************************/
/*       Static Page           */
/*******************************/

.static-page__static-container {
 width: var(--unit-ten);
 margin: 0 auto;
 padding: 30px 10px;
}

.static-page__fieldset {
    padding: 30px;
}

.static-page__content p {
    margin: 0 0 12px;
    font-size: 1.6rem;
    line-height: 1.5;
}

.static-page__header,
.static-page__sub-title {
    margin: 0 0 20px;
}

.static-page__title {
    text-transform: uppercase;
}

.static-page__content ol ol {
    list-style-type: lower-alpha;
}

.static-page__content ol ol ol {
    list-style-type: upper-roman;
}

.static-page__content ol ol ol ol {
    list-style-type: lower-roman;
}

.static-page__content li {
    font-size: 1.6rem;
}

@media screen and (max-width: 1200px) {
    
}

@media screen and (max-width: 768px) {
    
}