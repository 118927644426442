/*******************************/
/*      Forms                  */
/*******************************/

input:focus,
select:focus,
textarea:focus {
    outline: 1px solid var(--gold);
}

::placeholder {
    /* text-transform: capitalize; */
}

.eb-forms {
    width: 100%;
}

.eb-forms__form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    position: relative;
}

.eb-forms input[type="text"],
.eb-forms input[type="password"],
.eb-forms textarea {
    border-color: var(--grey-light);
    border-width: 1px;
    border-style: solid;
    padding: 5px 10px;
    width: 100%;
    height: 35px;
    margin-bottom: 0;
    font-size: 12px;
    font-family: var(--helvetica-regular);
    letter-spacing: 1px;
    text-transform: none !important;
    color: var(--grey-dark);
}

.eb-forms textarea {
    height: 175px;
    resize: none;
}

.eb-forms select {
    min-width: 150px;
    max-width: 200px;
    height: 26px;
    font-size: 11px;
    letter-spacing: 1px;
    margin-left: 16px; 
    padding: 5px 10px;
    border: 1px solid var(--grey-light);
    color: var(--grey-007);
    font-family: var(--helvetica-regular);
    height: 35px;
}

.eb-forms label {
    display: inline-block;
    font-size: 1.4rem;
    margin: 2px 0 10px;
}

.eb-forms input[type="checkbox"],
.eb-forms input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.eb-forms .eb-checkbox,
.eb-forms .eb-radio {
    position: relative;
    flex: 1 50%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    height: 25px;
}

.eb-forms .eb-checkbox label,
.eb-forms .eb-radio label {
    flex: 1;
    padding: 0 0 0 40px;
    text-align: left;
}

.eb-forms .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: transparent;
    border: 2px solid var(--gold);
    pointer-events: none;
}

.eb-forms .eb-radio .checkmark {
    border-radius: 100%;
}

.eb-checkbox,
.eb-checkbox > label,
.eb-radio,
.eb-radio > label {
    cursor: pointer;
}

.eb-radio--disabled,
.eb-radio--disabled > label {
    cursor: not-allowed;
    color: var(--grey-light);
}

.eb-checkbox > input:checked ~ .checkmark,
.eb-radio > input:checked ~ .checkmark {
    background-color: var(--gold);
}

.eb-checkbox > .checkmark:after,
.eb-radio > .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    left: 50%;
    top: 48%;
    width: 7px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: translateX(-50%) translateY(-48%) rotate(45deg);
}

.eb-checkbox > input:checked ~ .checkmark:after,
.eb-radio > input:checked ~ .checkmark:after {
    display: block;
}

.eb-forms select:disabled,
.eb-forms input:disabled,
.eb-forms textarea:disabled {
    color: var(--black);
    background-color: var(--grey-003);
}

.eb-forms-open input[type="text"] {
    border: none;
    border-bottom: 2px solid var(--black);
    background: transparent;
    width: 100%;
    height: 35px;
    font-size: 14px;
    color: var(--black);
    margin-bottom: 10px;
    padding-left: 5px;
}

.eb-forms-open input[type="text"]::placeholder{
    color: var(--black);
}

.eb-forms-open select {
    position: relative;
    margin: 0;
    width: 100%;
    height: 35px;
    padding: 5px 10px;
    border: none;
    color: var(--grey-007);
    border-radius: 0px !important;
    font-family: var(--helvetica-regular);
    background: var(--gold-001);
    border-bottom: 2px solid var(--black);
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
}

.eb-forms__form-group--open-select {
    position: relative;
}

.eb-forms__form-group--open-select::before {
    content: '\f0d7';
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 16px;
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}

.previewText {
    font-size: 13px;
    display: none;
}

@media screen and (max-width: 1200px) {
    .eb-forms__form-group {
        align-items: unset;
    }
    
}

@media screen and (max-width: 1024px) {
    .eb-forms--not-fullwidth input[type="text"] {
        margin-bottom: 10px;
    }
}
  
@media screen and (max-width: 768px) {
    .eb-forms select {
        margin: 0;
    }

    .eb-forms__form-group {
        flex-direction: column;
    }
}