#profilePageContainer {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: auto;
    width: 100%;
}

.profile__top-bar {
    display: flex;
    height: 100px;
    width: 100%;  
    border-bottom: 1px solid var(--grey-light);  
}

.profile__top-bar-left {
    width: var(--unit-one);
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile__top-bar-right {
    flex: 1;
    display: flex;
    align-items: center;
}

.profile__top-bar-right-title {
    color: #bf9000;
    letter-spacing: 1px;
    font-weight: bold;
    font-size: 2em;
    padding-top: 0;
}

.profile__image {
    height: 80px;
    border-radius: 50%;
    border: 1px solid;
}

.profile__image--dummy {
    border: none;
}

.profile__top-bar-left i {
    font-size: 80px;
    color: var(--gold);
}

.profile__main-container {
    display: flex;
    min-height: calc(100vh - 130px);
    overflow: hidden;
    transition: all .2s ease-in-out;
}

.profile__sidebar {
    position: sticky;
    top: 50px;
    background: var(--black-003);
    width: 80px;
    height: 100vh;
    transition: all 0.2s ease-in-out;
    overflow: hidden;
}

.profile__sidebar--open {
    width: var(--unit-four-three-quarter);
    max-width: 270px;
    transition: all 0.2s ease-in-out;
}

.profile__sidebar-trigger {
    background: transparent;
    border: none;
    width: 100%;
    cursor: pointer;
    margin: 0.5em 0;
    height: 25px;
    display: flex;
    justify-content: flex-end;
    padding: 1px 10px;
}

.profile__sidebar--open .profile__sidebar-trigger {
    width: 100%;
    text-align: right;
}

.profile__sidebar-trigger-icon {
    width: 20px;
    transition: all ease-in .2s;
    margin-left: 10px;
}

.profile__folders-inside--back-trigger--icon {
    width: 25px;
}

.profile__sidebar-trigger:hover .profile__sidebar-trigger-icon {
    transform: scale(1.2);
    transition: all ease-in .2s;
}

.profile__sidebar--open .profile__sidebar-trigger-icon,
.profile__folders-inside--back-trigger { transform: rotate(180deg) }

.profile__folders-inside--back-trigger { text-align: right }

.profile__sidebar--open .profile__sidebar-trigger:hover .profile__sidebar-trigger-icon {
    transform: scale(1.2) rotate(180deg);
    transition: all ease-in .2s;
}

.profile__sidebar-trigger:focus { outline: none }

.profile__sidebar-list {
    position: relative;
    border-color: var(--black);
    border-width: 0.5px 0 0;
    border-style: solid;
    height: 100%;
    z-index: 0;
    overflow: hidden;
}

.profile__sidebar-list::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background: var(--grey-012);
    left: 80px;
    z-index: -1;
}

.profile__sidebar-list-link {
    position: relative;
    display: block;
    padding: 0 5px 0 6px;
    border-width: 0 0 0 5px;
    border-style: solid;
    border-color: transparent;
}

.profile__sidebar-icon-name {
    position: relative;
    width: 50px;
}

.profile__sidebar-list-link-text {
    display: block;
    margin: 0 12px;
    text-transform: capitalize;
    color: var(--gold);
    font-weight: bold;
    border-bottom: 1px solid var(--grey-011);
    font-size: 1.4rem;
    line-height: 60px;
}

.profile__sidebar-list-item--active .profile__sidebar-list-link,
.profile__sidebar-list-link:hover,
.profile__sidebar-list-link:focus { background: var(--grey-012) }

.profile__sidebar-list-item--active .profile__sidebar-list-link { border-color: var(--secondary-colour) }

.profile__sidebar-list-link-name {
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    line-height: initial;
}

.profile__sidebar-list-item--selected .profile__sidebar-list-link { border-color: var(--gold) }

.profile__sidebar-icon {
    width: 50px;
    pointer-events: none;
    margin-bottom: 10px;
}

.profile__sidebar-icon--profile { padding: 7px }

.profile__sidebar-sublist {
    position: absolute;
    display: none;
    left: 80px;
    width: calc(100% - 80px);
    top: 0;
    height: calc(100vh - 130px);
    background: transparent;
    transform: translateX(-100%);
    opacity: 0;
    visibility: hidden;
}

.profile__sidebar-list-item--active .profile__sidebar-sublist { 
    display: block;
    background: var(--grey-12);
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
    z-index: 2;
}

.profile__sidebar-sublist-item {
    height: 48px;
    position: relative;
    display: flex;
}

.profile__sidebar-sublist-link {
    align-items: center;
    display: flex;
    flex-direction: row;
    text-transform: capitalize;
    font-size: 1.2rem;
    letter-spacing: 1px;
    color: var(--secondary-colour);
    transition: all ease-in-out .2s;
    border-width: 0 0 0 5px;
    border-style: solid;
    border-color: transparent;
    flex: 1;
    padding: 0 12px;
}

.profile__sidebar-sublist-link.active { 
    background: #999fb9;
    border-color: var(--secondary-colour);
}

.profile__sidebar-sublist-link:hover,
.profile__sidebar-sublist-link:focus {
    padding: 0 5px;
    border-color: var(--gold);
    transition: all ease-in-out .2s;
    color: var(--gold);
}

.profile__sidebar-sublist-link.active:hover,
.profile__sidebar-sublist-link.active:focus {
    padding: 0 12px;
    border-color: var(--secondary-colour);
    color: var(--secondary-colour); 
}

.profile__middle-content { flex: 1 }

.profile__image-hint {
    display: block;
    font-size: 13px;
    letter-spacing: 1px;
}
.profilePageInputText {
    border-color: #bfbfbf;
    border-style: solid;
    padding: 5px 10px;
    min-width: 170px;
    max-width: 350px;
    width: 350px;
    margin-bottom: 10px;
    margin-left: 2px;
    font-size: 18px;
    font-family: BebasNeue;
    letter-spacing: 1px;
    color: #FFFFFF;
    background-color: #f3f3f3;
    margin-left: 5px;
    border-radius: 5px;
    border-width: 2px;
    height: 20px;
}
.labelText {
    font-weight: bold;
    color: #8b8b8b;
    font-size: 1em;
}
.labelInputWrapper {
    width: 10%;
    float: left;
    padding-top: 5px;
}
.inputTextWrapper label {
    max-width: 150px;
}
.eventCategoriesDropDown {
    width: 375px;
    margin-left: 5px;
    height: 35px;
    border: 2px solid #bfbfbf;
}

.rodal--profile-pic .rodal-dialog {
    width: 600px !important;
    border-radius: 5px;
    min-height: 470px;
}

.profile__rodal-text { padding: 15px }

.image-file-select-container {
    flex: 1;
    border: 1px solid var(--grey-010);
    position: relative;
}

.image-preview-container {
    position: relative;
    overflow: hidden;
    width: 50%;
    margin-right: 15px;
    height: 250px;
    border: 1px solid var(--grey-010);
}

.image-preview {
    position: absolute;
    height: 100%;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: -18px;
}

.image-preview--cropped-img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.image-preview--main-cropped-img {
    width: auto;
    height: 100%;
}

.image-upload-wrap {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--grey-007);
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    border: 1px dashed var(--grey-010);
    padding: 10px;
}

.image-upload-wrap .uploaded-img-src .primary.button { display: none; }
.image-upload-wrap .uploaded-img-src .close span.null { 
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    position: absolute;
}

.image-file-select {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    top: 0px;
    left: 0px;
    cursor: pointer;
}

.image-crop-complete,
.image-crop-cancel,
.image-crop-remove {
    width: 110px;
    text-align: center;
}

.image-crop-remove {
    position: relative;
    margin: 10px;
    width: auto;
}

.image-cropped {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    text-align: center;
}

.cover-pro-container.eb-forms__form-group {
    flex-direction: column;
    margin-bottom: 50px;
}

.cover-pic-container {
    height: 400px;
}

.profile__profile-photo {
    border: 1px dashed var(--grey-010);
    cursor: pointer;
    height: 100px;
    width: 100px;
    margin: 0;
    position: absolute;
    bottom: -10%;
    left: 20px;
    background-image: url('https://via.placeholder.com/150/f6f5f0');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.profile__profile-photo p {
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
    text-align: center;
}

.profile__profile-photo--cropped {
    display: block;
    border-radius: 5px;
}

.profile__top-bar-right-heading {
    flex: 1 65%;
    display: flex; 
}

.profile__top-bar-right-logo {
    height: 100px;
}

.profile__button-container {
    display: flex;
    justify-content: space-between;
    width: var(--unit-nine);
}

.profile__business-info .profile__button-container,
.profile__change-password .profile__button-container,
.profile__services-location .profile__button-container  { justify-content: space-between; }

.profile__business-info  .profile__button-container--single {
    justify-content: flex-end;
    width: 100%;
}

.cover-photo_wrapper_extended { flex: 1; }

.profile__button-container--reviews { justify-content: space-between }

.profile__button {
    min-width: 100px;
    width: auto;
}

.profile__header {
    background-color: var(--gold-001);
    border-bottom: 1px solid var(--grey-light);
}

.profile__header-title { 
    color: var(--gold);
    padding: 20px 30px;
}

.profile__section {
    position: relative;
    padding: 20px;
    width: var(--unit-eleven);
    margin: 0 auto;
}

.profile__section--success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 250px;
}

.profile__section--success-message { display: block }

.profile__button-success { margin: 10px 0 }

.profile__sub-title { margin-bottom: 30px }

.profile__form-container { flex: 1 }

.profile__input-container { flex: 1 }

.profile__input-container input,
.profile__input-container select,
.profile__input-container textarea { width: 100% }

.profile__tips {
    width: var(--unit-one);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.profile__tooltip {
    width: 24px;
    height: 24px;
    text-align: center;
    padding: 1px 5px;
    border: 1px solid;
    font-weight: bolder;
    border-radius: 100%;
}

.profile__tips:hover .profile__tooltip,
.profile__tips:focus .profile__tooltip {
    background: var(--black);
    color: var(--secondary-colour);
}

.profile__tooltiptext {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transition: all ease-in-out .2s;
    width: 200px;
    background-color: var(--grey-012);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    right: 0;
    top: 92%;
    z-index: 1;
    box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.5);
    font-size: 12px;
}

.profile__tooltiptext:after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent var(--grey-012) transparent;
    top: -7px;
    right: 23px;
}

.profile__tips:hover .profile__tooltiptext,
.profile__tips:focus .profile__tooltiptext {
    visibility: visible;
    opacity: 1;
    transition: all ease-in-out .2s;
 }

.eb-forms--profile label {
    padding: 0 20px 0 0;
    text-align: right;
}

.eb-forms--profile-password label { width: var(--unit-three) }

.eb-forms--profile input[type="text"],
.eb-forms--profile select,
.eb-forms--profile textarea {
    flex: 1;
    margin-bottom: 0;
}

.eb-forms--profile select {
    max-width: 100%;
    margin: 0;
    text-transform: capitalize;
    height: 35px;
}

.eb-forms--profile #company-name,
.eb-forms--profile #supplier-type { text-transform: uppercase }

.eb-forms--not-fullwidth {
    display: flex;
    flex: 1;
    align-items: center;
}

.eb-forms--not-fullwidth select,
.eb-forms--not-fullwidth input { margin: 0 10px 0 0 }

.eb-forms--not-fullwidth > *:last-child { margin: 0 }

.eb-forms--not-fullwidth .container { margin-right: 10px; flex: 1; }

.eb-forms--not-fullwidth .container:last-of-type { margin: 0; } 

.eb-forms--not-fullwidth .react-dropdown-select { min-width: auto !important; }

.profile__form-container div {
    color: var(--grey-dark) !important;
    font-size: 12px !important;
}

.react-dropdown-select-content input {
    font-size: small;
}

#telephone-country-code,
#mobile-country-code { width: var(--unit-one) }   

.profile__supplier-subcategories-container,
.profile__supplier-additional-services-container,
.profile__supplier-cities-container {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
}

.btn-gold--profile-add-services {
    height: 35px;
}

.profile__supplier-additional-services,
.profile__supplier-additional-areas {
    margin: 0 15px 10px 0;
    text-transform: capitalize;
}

.profile__button-skip {
    border: none;
    width: auto;
}

.profile__successs-message {
    opacity: 1;
} 

.profile__successs-message.hide {
    opacity: 0;
}

.profile__folder-container {
    display: flex;
    flex-direction: row;
    padding: 30px;
    flex-wrap: wrap;
}

.profile__middle-content h3 {
    margin-top: 25px;
    margin-left: 25px;
}

.profile__folders {
    display: flex;
    cursor: pointer;
    flex-direction: row;
    width: calc((100% / 12 * 6) - 20px);
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.15);
    margin: 0 20px 30px 0;
    padding: 10px;
}

.profile__folders:hover,
.profile__folders:focus {
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.45);
}

.profile__folders:nth-child(4n) {
    margin-right: 0;
}

.profile__folders__folder-icon {
    width: auto;
    height: 30px;
}

.profile__folder-name {
    padding: 6px 20px;
    width: var(--unit-ten);
    border-radius: 4px;
    text-transform: uppercase;
    color: #a1a1a1;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
}

.profile__folder-date {
    color: #a1a1a1;
    font-size: 14px;
}

.profile__folders--inside {
    width: calc(var(--unit-twelve) - 14px);
    margin-bottom: 20px;
}

.profile__folders-inside label {
    font-size: 14px;
}

.profile__event-information-block span {
    font-weight: normal;
    font-size: 14px;    
}

.profile__folder {
    display: flex;
    padding: 0 30px;
    align-items: center;
}

.profile__acting-label {
    margin: 0 0 10px;
    padding: 0 20px 0 calc(var(--unit-two));
}

.error__fields-indicator {
    border: 1px solid var(--gold) !important; 
}

#addEventSection .error__fields-indicator-profile {
    border: 1px solid var(--gold) !important; 
    margin-left: 0;
}

#appearIn { margin-left: 0 }

.add__events-internal-wrapper {
    border: 1px solid #afafaf !important;
    padding: 20px;
    margin-bottom: 20px;
}

.fullwidth { width: 100% !important }

.profile__folders-img-container {
    position: relative;
    width: calc(100% / 12 * 6);
    overflow: hidden;
}

.profile__folders-img--multiple {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 20px;
    height: 20px;
}

.profile__folders-img-container:after {
    content: '';
    display: block;
    padding-bottom: 100%;
}

.profile__folders-img-container img {
    position: absolute;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    transition: 1.2s opacity ease-in-out; opacity: 1;
}

.profile__folders-text-container {
    flex: 1;
    padding-left: 10px;
}

.profile__folders-description {
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 6;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.profile__folders-date { margin-top: 0; }

@media screen and (max-width: 1200px) {
    .rodal--profile-pic .rodal-dialog {
        width: 75vw !important;
        height: 75vh !important;  
        border-radius: 5px;
        min-height: 600px;
    }

    .profile__tips-section {
        width: var(--unit-four);
    }

    .eb-forms--not-fullwidth {
        display: block;
    }

    .eb-forms--not-fullwidth select, 
    .eb-forms--not-fullwidth input[type="text"] {
        width: 100%;
        margin: 0 0 10px;
    }

    .eb-forms--not-fullwidth > *:last-child,
    .eb-forms--not-fullwidth > input[type="text"]:last-child {
        margin: 0;
    }

    #telephone-country-code, 
    #mobile-country-code {
        width: var(--unit-twelve);
    }

    .profile__acting-label {
        padding: 0 20px 0 calc(var(--unit-three));
    }
}

.react-datepicker-wrapper {
    margin-right: 10px;
}
  
.react-datepicker {
    font-size: 12px;
}

.eb-forms--profile label {
    text-align: left;
}

.profile__button-container {
    width: 100%;
}

.eb-forms input[type="text"],
.eb-forms input[type="password"],
.eb-forms textarea {
    margin-bottom: 0;
}

.error__fields-indicator-profile {
    border: none;
}

.error__fields-indicator-profile a {
    top: 54% !important;
    right: 3px !important;
    margin-top: -20px !important;
    padding: 2px 9px !important;
    font-size: 12px !important;
    letter-spacing: 1px !important;
    background: var(--gold) !important;
    color: #fff !important;
}

.error__fields-indicator-profile input[type="text"] {
    border: 1px solid #f00 !important;
    width: 100%;
}

.error__fields-indicator-profile input[type="password"] {
    border: 1px solid #f00 !important;
    width: 100%;
}

.galleryContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: var(--unit-twelve);
    height: 400px;
    padding-bottom: 50px;
    margin: 30px auto;
    overflow-y: auto;
    overflow-x: hidden;
}

.gallery-container__image {
    width: calc(var(--unit-four) - 15px);
    margin: 0 15px 10px 0;
    list-style: none;
}

.gallery-container__image:nth-child(3n) {
    margin-right: 0;
}

.gallery-container__image:hover {
    box-shadow: 0 0 20px var(--grey-002);
    padding: 10px;
    border: 1px solid var(--grey-002);
}

.gallery-container__image .thumbnail img {
    width: 100%;
    height: 100%;
}

.additional-services__block {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.additional-services__block svg {
    fill: var(--gold);
    width: 10px;
    height: 10px;
    margin-right: 15px;
    cursor: pointer;
}

.additional-services__block h6 {
    color: var(--grey-dark);
    text-transform: capitalize;
    font-size: 14px !important;
    letter-spacing: 1px !important;
}

.profile__event-information-block {
    padding: 6px 20px;
    width: var(--unit-twelve);
    background: var(--grey-010);
    border-radius: 4px;
    text-transform: uppercase;
    width: 99%;
    margin-top: 15px;
    min-height: 100px;
    margin-bottom: 20px;
}

.profile__event-information-each-block {
    display: flex;   
}

.profile__sidebar-list-item {
    line-height: 80px;
}

#singleFolderName {
    width: auto;
    height: 35px;
}

.arrowBack { 
    transform: rotate(180deg);
    cursor: pointer; 
    margin-bottom: 25px;
}

.profile__layout-container {
    display: flex;
    flex-direction: row;
}

.additional__margin-left {
    transition: all .2s ease-in-out; 
}

.btn { 
    height: 35px;
    border-radius: 5px; 
}

.btn-transparent::before, 
.btn-transparent::after {
    height: 35px;
    width: 35px;
}

button:disabled { border-radius: 5px }

.cover-photo_wrapper { flex: 1; }

#fileInputPreviewLabel { 
    cursor: pointer;
    width: auto; 
    font-size: 8px;
    padding: 0 10px;
}

#fileInputPreviewLabel:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

#job-cost {
    height: 35px;
    margin-left: 0;
}
 
#fileInputPreview {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

.uploaded--images_label + div { flex: 1; } 

.profile__section-content-tips { 
    font-size: 13px;
    color: var(--gold);
}

.appear-in-container {
    display: flex;
    flex-direction: column;
}

.appear-in-btn-container {
    display: flex;
    margin-bottom: 15px;
}

.btn.btn-appear-in { 
    margin-right: 20px;
    border-radius: 100%;
    width: 75px;
    height: 75px;
    background: var( --grey-010);
    border: 2px solid var( --grey-010);
    padding: 0 8px;
}

.btn.btn-appear-in:hover,
.btn.btn-appear-in:focus {
    border-color: var(--gold);
}

.btn.btn-appear-in.active {
    border-color: var(--gold);
    background: var(--gold);
    color: var(--secondary-colour);
}

.profile__form-container .appear-in-help {
    position: relative;
    margin: 10px 0;
    padding: 20px;
    background: #90ACBA;
    color: var(--secondary-colour) !important;
    width: 50%;
    border-radius: 5px;
}

.profile__form-container .appear-in-help:before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 17px;
    width: 20px;
    height: 20px;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #90ACBA;
}


.extraMarginnLeft { 
    margin-left: 20px;
    margin-top: 15px; 
}

#supplierAutoSuggesions {
    min-width: 200px;
    width: 100%;
}

.leftMarginEmpty { margin-left: 0 !important }

.buttonLocationRight { justify-content: flex-end }

#selectedSuppliersList {
    margin-left: 0;
    min-height: 200px;
    height: auto;
    overflow-y: auto;
    min-width: 265px;
}

.dropzone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: -webkit-max-content;
    width: -moz-max-content;
    border: 1px dashed var(--grey-010);
    color: var(--grey-007);
    padding: 15px;
    max-height: unset;
    overflow-y: auto;
    min-height: 200px !important;
    width: 200px;
}

.dropzone:focus { outline: none }

.dropzoneContainer {
    display: flex;
    flex-direction: row;
    height: 200px;
}

#event-year { margin-left: 0 }

.textAlignRight { text-align: right !important }

.contentLeftToRight {
    display: flex;
    justify-content: flex-end;
}

.mi-input-file {
    border: none;
    padding: 35px;
    height: auto;
    width: auto;
    position: relative;
    text-align: center;
    margin-left: 0;
}

.image-preview {
    position: absolute;
    height: 100%;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: 0;
    /*width: 100%;*/
}

.rodal__btn-container {
    justify-content: flex-end !important;
}

.sub--heading_presentations {
    color: var(--gold) !important;
    margin-bottom: 15px;
    margin-top: 20px;
}

.profile__section-content {
    border: 1px solid var(--gold);
    border-radius: 5px;
    padding: 20px 40px;
    margin-bottom: 35px;
}

.extra__hint-style {
    width: 100% !important;
    color: var(--gold) !important;
    font-size: 13px !important;
}

.css-dvua67-singleValue {
    color: var(--grey-dark) !important;
}

.width__Half {
    width: 50% !important;
}

.required__indicator {
    color: var(--gold) !important;
    font-size: 16px;
}

.eb-forms--profile label {
    line-height: 18px;
}

.gallery__content-wrapper {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
}

.react-fancybox {
    position: relative;
    width: calc((100% / 12 * 4) - 7px);
    margin: 0 10px 10px 0;
}

.react-fancybox:nth-of-type(3n) {
    margin-right: 0;
}

.react-fancybox:after {
    content: '';
    display: block;
    padding-bottom: 50%;
}

.react-fancybox .thumbnail img {
    max-width: 100% !important;
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    left: 0;
    top: 0;
}

.profile__business-info, 
.profile__services-location, 
.profile__add-events, 
.supplier-profile__reviews, 
.profile__contact-info, 
.profile__change-password,
.profile__my-folders {
    padding: 30px;
}

.profile__section .rodal--profile-pic .rodal-dialog {
    min-height: 470px;
}

.lower__case-text {
    text-transform: capitalize !important;
}

.password__mask-wrapper {
    width: 100% !important;
}

.react-photo-gallery--gallery .uploaded-image {
    position: relative;
    width: calc(100% / 12 * 4 - 20px);
    margin: 0 20px 20px 0;
    cursor: move;
    transition: all 0.2s linear;
}

.react-photo-gallery--gallery .uploaded-image:nth-child(3n) {
    margin-right: 0;
}

.react-photo-gallery--gallery .uploaded-image:before {
    content: '';
    display: block;
    padding-bottom: 100%;
}

.uploaded-image img {
    cursor: grab;
    position: absolute;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    top: 0;
    margin: 0 !important;
}

.uploaded-image .close {
    background: #a47c00;
    position: absolute;
    top: -5px;
    right: -5px;
    border-radius: 50%;
    padding: 1px 8px;
    color: #ffffff;
    transform: translate(0, 0);
    font-size: 16px;
    border: 1px solid;
    cursor: pointer;
}

.uploaded-image .close:hover,
.uploaded-image .close:focus {
    background: #ffffff;
    color: #a47c00;
}

.eb-forms__form-group--photo-upload {
    display: flex;
    flex-direction: row !important;
}

.eb-forms__form-group--photo-upload #eventDescription, 
.eb-forms__form-group--photo-upload #eventDescription:focus {
    border: none;
    outline: none;
    height: 200px;
}

.eb-forms__form-group--photo-upload .events-photos {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0 0;
}

.dropzone-inner {
    position: relative;
    cursor: pointer;
}

.dropzone-inner__icon-container {
    width: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
}

.dropzone-inner__icon--plus {
    position: absolute;
    top: -10px;
}

.events-photos > .events-photos__photo-container {
    position: relative;
    width: calc((100% / 12 * 4) - 7px);
    margin: 0 10px 10px 0;
}

.events-photos > .events-photos__photo-container:nth-of-type(3n) {
    margin-right: 0;
}

.events-photos > .events-photos__photo-container:after {
    content: '';
    display: block;
    padding-bottom: 50%;
}

.events-photos > .events-photos__photo-container img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    left: 0;
    top: 0;
}

.overrided-style__next_btn {
    margin-right: 10px;
}

.react-fancybox .box .image-box {
    position: relative;
    width: calc(70vw);
}

.react-fancybox .box .image-box:after {
    content: '';
    display: block;
    padding-bottom: 50%;
}

.react-fancybox .box .image-box img:not(.close-btn) {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    left: 0;
    top: 0;
    max-height: 100% !important;
}

@media screen and (max-width: 1400px) {
    .cover-pic-container {
        height: 310px;
    }
}

@media screen and (max-width: 1200px) {
    .eb-forms input[type="text"],
    .eb-forms input[type="password"],
    .eb-forms textarea {
        margin-bottom: 0;
    }

    .react-datepicker-wrapper,
    .react-datepicker__input-container {
        display: block;
    }

    .react-datepicker-wrapper {
        margin-right: 0;
    }

}

@media screen and (max-width: 1024px) {
    .profile__folders-description {
        -webkit-line-clamp: 2;
    }
}

@media screen and (max-width: 768px) {
    .profile__section {
        width: var(--unit-twelve);
        padding: 10px;
    }

    .eb-forms--profile label {
        margin-bottom: 10px;
    }

    .profile__tips {
        position: absolute;
        right: 0;
    }

    .profile__sidebar--open {
        width: var(--unit-five);
        z-index: 1;
    }

    .profile__sidebar {
        width: 60px;
        top: 120px;
    }

    .profile__sidebar-icon {
        width: 34px;
    }

    .profile__sidebar--open {
        width: var(--unit-nine);
    }

    .profile__sidebar--open + #profilePageContainer {
        overflow: hidden;
    }

    .profile__sidebar-list::after {
        left: 60px;
    }
    
    .profile__sidebar-list-item {
        line-height: 60px;
    }

    .profile__sidebar-icon-name {
        width: 40px;
    }

    .profile__sidebar-list-link-name {
        font-size: 10px;
    }

    .profile__sidebar-icon--profile {
        padding: 4px;
    }

    .profile__sidebar-sublist {
        left: 60px;
        width: calc(100% - 60px);
    }

    #profilePageContainer {
        margin-left: 0;
        flex: 1;
    }

    .profile__folder-container,
    .profile__business-info, 
    .profile__services-location, 
    .profile__add-events, 
    .supplier-profile__reviews, 
    .profile__contact-info, 
    .profile__change-password,
    .profile__my-folders {
        padding: 20px;
    }

    .profile__button {
        width: var(--unit-twelve);
    }

    .profile__acting-label {
        padding: 0;
    }

    .additional__margin-left {
        margin: 0;
        filter: blur(3px);
    }

    .profile__folders { 
        width: calc((100% / 12 * 6) - 10px);
        margin: 0 15px 15px 0;
        flex-direction: column;
    }

    .profile__folders:nth-child(2n) {
        margin-right: 0;
    } 

    .profile__folders-img-container,
    .profile__folders-text-container {
        width: 100%;
        padding: 0;
    }

    .profile__folders-date {
        white-space: pre-wrap;
    }

    .profile__folders-description {
        -webkit-line-clamp: 3;
    }

    .dropzoneContainer {
        flex-direction: column;
        height: auto;
    }

    .dropzone {
        width: 100%;
    }

    .eb-forms__form-group--photo-upload #eventDescription {
        margin-top: 20px;
        outline: 1px solid;
    }

    .events-photos > .events-photos__photo-container {
        flex: 40%;
    }

    .profile__button-container--single {
        flex-direction: column;
    }

    .profile__button-container--single .profile__button-next:not(:last-of-type) {
        margin: 0 0 20px;
    }
}

.sub__service-or-location-content-wrapper {
    position: relative;
    margin: 0 25px;
}

.sub__service-or-location-content-wrapper .close {
    background: #a47c00;
    position: absolute;
    top: 50%;
    right: 0;
    border-radius: 50%;
    padding: 1px 8px;
    color: #ffffff;
    transform: translate(0, -50%);
    font-size: 16px;
    border: 1px solid;
    cursor: pointer;
}

.location-list {
    width: 30%;
    min-width: 470px;
}

.location-list p {
    font-size: 14px;
    color: #756658;
    padding: 5px 0;
    line-height: 26px;
}

.panel > button + .close {
    background: #a47c00;
    position: absolute;
    /* top: 70%; */
    left: -15px;
    border-radius: 35%;
    padding: 1px 8px;
    color: #ffffff;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    font-size: 15px;
    border: 1px solid;
    cursor: pointer;
    margin-top: -13px;
}

@media screen and (max-width: 767px) { 
    .cover-pic-container {
        height: 140px;
    }

    .profile__profile-photo {
        height: 80px;
        width: 80px;
    }
}

