/* Images */
.file-list {
    margin: 10px 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}

.file-item {
    position: relative;
    width: calc(100% / 12 * 4 - 20px);
    margin: 0 20px 20px 0;
    cursor: move;
    transition: all 0.2s linear;
}

.file-item:nth-child(3n) {
    margin-right: 0;
}

.file-item:before {
    content: '';
    display: block;
    padding-bottom: 100%;
}

.file-img {
    position: absolute;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    top: 0;
}

.file-item .close {
    background: #a47c00;
    position: absolute;
    top: -5px;
    right: -5px;
    border-radius: 50%;
    padding: 1px 8px;
    color: #ffffff;
    transform: translate(0, 0);
    font-size: 16px;
    border: 1px solid;
}

.file-item .close:hover,
.file-item .close:focus {
    background: #ffffff;
    color: #a47c00;
}

/* Tablets */
@media (max-width: 1000px) {
    .App {
        max-width: 600px;
    }
    .file-item {
        width: calc(50% - 22px);
        height: 200px;
    }
}

/* Mobiles */
@media (max-width: 640px) {
    .App {
        max-width: 100%;
        padding: 0 15px;
    }
    .file-item {
        width: calc(100% - 22px);
        height: 200px;
    }
}