.profile__section-content {
    width: 100%;
    padding: 0;
}

.eb-forms textarea {
    min-height: 175px;
}

.rodal--profile-pic .rodal-dialog {
    min-height: 475px;
}

.rodal__main-container, .rodal__btn-container {
    padding-bottom: 0;
}

.profile__section-content {
    margin-bottom: 0;
}

.profile__section-content {
    border: none;
}