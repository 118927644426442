.find-pro__container {
    padding: 30px;
    column-count: 4;
    column-gap: 40px;
}

.find-pro__categories,
.find-hire__categories {
    display: inline-block;
    margin: 0 0 16px;
    width: 100%;
}

.find-pro__category-title,
.find-hire__category-title {
    color: var(--grey-004);
    font-size: 1.3rem;
    line-height: 1.5;
    font-weight: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-bottom: 1px solid var(--grey-003);
    padding-bottom: 2px;
}

.find-pro__sub-category-title,
.find-hire__sub-category-title {
    display: block;
    margin: 0;
    color: var(--grey-007);
    text-transform: capitalize;
    font-size: 12px;
    letter-spacing: 1px;
    text-decoration: none;
    cursor: pointer;
}

.empty-a-pro-hire-link:hover {
    color: var(--grey-007);
}
.filled__find-a-pro-hire-link:hover {
    color: var(--gold-002);
}

.searchable-dropdown {
    font-size: 13px;
    letter-spacing: 1px;
    color: #000;
    margin-right: 10px;
}

.container input[type="text"] {
    height: 15px;
    min-width: 150px;
    margin-bottom: 0;
    cursor: pointer;
}

.filter__search input[type="text"] {
    height: 38px;
    min-width: 200px;
    border-radius: 5px;
    padding: 0 5px;
    border-style: solid;
    border-color: #b1b1b1;
    border-width: 1px;
} 

.find-pro-detail__container {
    display: flex;
    flex: 1;
    flex-direction: row;
    margin-top: 0px;
    border-top: none;
}

.find-pro__back-container {
    border-bottom: none;
    padding: 10px 30px;
}

.find-pro__back {
    display: flex;
    align-items: baseline;
    font-size: 2rem;
    line-height: 3rem;
    color: var(--black);
}

.find-pro__back-chevron {
    height: 15px;
    transform: rotate(180deg);
    padding: 0 0 0 10px;
}

.find-pro__sidebar {
    position: sticky;
    top: 50px;
    height: 100vh;
    background: var(--black-003);
    transition: width 1000s ease-in-out;
}

.find-pro__sidebar--open {
    width: var(--unit-four-three-quarter);
    max-width: 200px;
    transition: width 1000s ease-in-out;
}

.find-pro__sidebar-trigger {
    padding: 2px 10px;
}

.find-pro__sidebar .profile__sidebar-trigger:hover .profile__sidebar-trigger-icon {
    transform: scale(1.2);
    transition: all ease-in .2s;
}

.find-pro__sidebar--open .profile__sidebar-trigger:hover .profile__sidebar-trigger-icon {
    transform: scale(1.2) rotate(180deg);
    transition: all ease-in .2s;
}

.find-pro__sidebar .find-pro__sidebar-list {
    opacity: 0;
    visibility: hidden;
    width: 0;
    transition: visibility .2s ease-in-out;
    border-color: var(--black);
    border-style: solid;
    border-width: 0.5px 0 0;
}

.find-pro__sidebar--open .find-pro__sidebar-list {
    padding: 10px 30px;
    opacity: 1;
    visibility: visible;
    width: 100%;
    transition: visibility .2s ease-in-out;
}

.find-pro__sidebar--open .profile__sidebar-trigger {
    width: 100%;
    text-align: left;
    color: #fff;
    font-size: 18px;
}

.find-pro__sidebar--open .profile__sidebar-trigger-icon {
    transform: rotate(180deg);
}

.find-pro__sidebar-list-item {
    font-size: 14px;
    font-weight: 300;
    padding: 10px 0;
}

.find-pro__sidebar-list-link {
    color: var(--secondary-colour);
    transition: all ease-in-out .2s;
}

.find-pro__sidebar-list-link:hover,
.find-pro__sidebar-list-link:focus {
    color: var(--gold);
    transition: all ease-in-out .2s;
}

.find-pro__gallery-container {
    border-left: 1px solid var(--grey-light);
    border-top: 1px solid var(--grey-light);
    width: 100%;
}

.find-pro__gallery-title {
    position: sticky;
    top: 50px;
    padding: 10px 20px;
    top: 50px;
    background: var(--secondary-colour);
    z-index: 2;
    border-bottom: 1px solid var(--grey-light);
    width: 100%;
}

.find-pro__gallery {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    flex: 1;
}

.find-pro__gallery__container {
    position: relative;
    width: calc(var(--unit-four) - 14px);
    margin: 0 0 20px 21px;
}

.find-pro__gallery__container:first-child, 
.find-pro__gallery__container:nth-child(3n+1) {
    margin-left: 0;
}

.find-pro__gallery__image {
    width: 100%;
    position: relative;
}

.find-pro__gallery__image::after {
    content: "";
    display: block;
    padding-bottom: 100%;
}

.find-pro__gallery__image-item {
    position: absolute;
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
    transition: 1.2s opacity ease-in-out; 
    opacity: 1;
}

.find-pro__gallery-heading {
    margin: 10px 0;
}

.find-pro__footer {
    display: flex;
    justify-content: space-between;
}

.find-pro__description {
    margin-bottom: 10px;
    min-height: 75px;
}

.find-pro__tag-list {
    margin-left: -5px;
}

.find-pro__tag-list-item {
    display: inline-block;
    padding: 5px 15px;
    border-radius: 24px;
    margin: 0 5px 5px;
    font-size: 13px;
    background-color: var(--gold);
    color: #fff;
    text-transform: capitalize;
}

.find-pro__search .header__search-bar {
    position: relative;
    padding: 15px;
    border-top: 0;
    border-bottom: 1px solid var(--grey-light);
}

.find-pro__search .header__input-container {
    position: relative;
}

.find-pro__search .header__search-bar-button {
    position: absolute;
    right: 0;
    background: transparent;
    height: 35px;
}
.find-pro__search .header__search-bar-close-icon {
    padding: 12px 15px;
}


@media screen and (max-width: 1200px) {
    .find-pro__container {
        column-count: 3;
        column-gap: 30px;
    }

    .find-pro__gallery__container {
        width: calc(var(--unit-six) - 11px);
        margin: 0 0 20px 21px;
    }

    .find-pro__gallery__container:first-child, 
    .find-pro__gallery__container:nth-child(2n+1) {
        margin-left: 0;
    }
}

@media screen and (max-width: 1024px) {
    .searchable-dropdown {
        margin: 0 0 10px;
    }   
}

@media screen and (max-width: 768px) {
    .find-pro__footer .btn-gold {
        width: auto;
    }

    .find-pro__sidebar--open {
        width: var(--unit-eight);
    }
}

@media screen and (max-width: 767px) {
    .find-pro__container {
        column-count: 2;
        column-gap: 20px;
    }

    .find-pro__gallery__container {
        width: var(--unit-twelve);
        margin: 0 0 20px;
    }

    .find-pro__tag-list {
        display: none;
    }

    .find-pro__gallery-heading {
        margin: 5px 0;
        font-size: 2rem;
        line-height: 3rem;
    }    

    .find-pro__footer .btn-gold {
        width: var(--unit-twelve);
    } 
}

.hire__container {
    padding: 30px;
    column-count: 4;
    column-gap: 40px;
}

.hire__categories {
    display: inline-block;
    margin: 0 0 16px;
    width: 100%;
}

.hire__category-title {
    color: var(--grey-004);
    font-size: 1.3rem;
    line-height: 1.5;
    font-weight: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-bottom: 1px solid var(--grey-003);
    padding-bottom: 2px;
}

.hire__sub-category-title {
    display: block;
    margin: 0;
    color: var(--grey-007);
    text-transform: capitalize;
    font-size: 12px;
    letter-spacing: 1px;
    text-decoration: none;
}

@media screen and (max-width: 1200px) {
    .hire__container {
        column-count: 3;
        column-gap: 30px;
    }
  
}

@media screen and (max-width: 768px) {
    .hire__container {
        column-count: 2;
        column-gap: 20px;
    }
}

.find-pro__sidebar-list-item > .active {
    color: var(--gold) !important;
}

.no__suppliers-error {
    color: var(--gold);
    margin-left: 25px;
    margin-top: 25px;
    font-size: 20px;
    width: 95%;
    text-align: center;
    border-radius: 10px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 200px;
    height: auto;
    padding-top: 20px;
}

.btn-gold {
    background: var(--gold);
    color: var(--secondary-colour);
    border: none;
    padding: 0 10px;
    min-width: 150px;
}

.rodal__header {
    padding: 14px;
    background-color: var(--gold) !important;
    color: #fff;
}

.rodal__heading h4 {
    letter-spacing: 1px;
}

.rodal-close:before, .rodal-close:after {
    position: absolute;
    content: '';
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #000;
    border-radius: 100%;
    transition: background 0.2s;
}

.filled__find-a-pro-hire-link {
    color: var(--gold);
    text-transform: capitalize;
}

.empty-a-pro-hire-link {
    color: var(--grey-003);
}